import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  header: '',
  body: '',
  icon: undefined,
  show: false,
};

const notificationToastSlice = createSlice({
  name: 'notificationToast',
  initialState,
  reducers: {
    resetNotificationToast: () => initialState,
    setContentNotificationToast: (state, { payload }) => {
      state.body = payload?.body;
      state.header = payload?.header;
      state.icon = payload?.icon;
    },
    toggleNotificationToast: (state) => {
      state.show = !state.show;
    },
  },
});

export const {
  resetNotificationToast,
  setContentNotificationToast,
  toggleNotificationToast,
} = notificationToastSlice.actions;

export default notificationToastSlice.reducer;
