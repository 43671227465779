/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGraphsSite = /* GraphQL */ `
  query GetGraphsSite($path: ID!) {
    getGraphsSite(path: $path) {
      path
      private
      Graphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGraphsSites = /* GraphQL */ `
  query ListGraphsSites(
    $path: ID
    $filter: ModelGraphsSiteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGraphsSites(
      path: $path
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        path
        private
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGraph = /* GraphQL */ `
  query GetGraph($id: ID!) {
    getGraph(id: $id) {
      id
      data
      config
      label
      type
      createdAt
      updatedAt
      graphsSiteGraphsPath
      __typename
    }
  }
`;
export const listGraphs = /* GraphQL */ `
  query ListGraphs(
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        config
        label
        type
        createdAt
        updatedAt
        graphsSiteGraphsPath
        __typename
      }
      nextToken
      __typename
    }
  }
`;
