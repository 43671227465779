import Plotly from 'plotly.js/dist/plotly-custom';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useGraphContainer } from '../../utils';
import enLang from '../../assets/i18n/en.json';

const CONFIGS = {
  TREEMAP: {
    type: 'treemap',
    branchvalues: 'total',
    textinfo: 'label',
    hovertemplate: `<b>%{label}</b><br />${enLang.editor.graph_labels.members}: %{value}<br />${enLang.editor.graph_labels.description}: %{text}<extra></extra>`,
  },
  SUNBURST: {
    type: 'sunburst',
    branchvalues: 'total',
    textinfo: 'label',
    hovertemplate: '<b>%{label}</b><br />%{text}<extra></extra>',
  },
};

function Graph({
  rawSeletedGraphData,
  selectedGraphType,
  selectedGraphConfig,
}) {
  const { GraphContainer, graphContainerRef, size } = useGraphContainer();
  useEffect(() => {
    if (!rawSeletedGraphData) return;

    Plotly.newPlot(
      graphContainerRef.current,
      [
        {
          ...rawSeletedGraphData,
          ...CONFIGS?.[selectedGraphType],
        },
      ],
      {
        margin: { l: 0, r: 25, b: 0, t: 20 },
        colorway: selectedGraphConfig?.colorway?.split(/,\s*/g),
      },
      { displaylogo: false }
    );
  }, [rawSeletedGraphData]);

  useEffect(() => {
    if (!graphContainerRef.current) return;

    Plotly.relayout(graphContainerRef.current, size);
  }, [size]);

  useEffect(() => {
    if (!graphContainerRef.current) return;

    Plotly.relayout(graphContainerRef.current, {
      colorway: selectedGraphConfig?.colorway?.split(/,\s*/g),
    });
  }, [selectedGraphConfig?.colorway]);

  if (!rawSeletedGraphData) {
    return <div>{enLang.editor.graph_labels.empty_graph}</div>;
  }

  return GraphContainer;
}

Graph.propTypes = {
  rawSeletedGraphData: PropTypes.shape({}),
  selectedGraphType: PropTypes.string,
  selectedGraphConfig: PropTypes.shape({ colorway: PropTypes.string }),
};

Graph.defaultProps = {
  rawSeletedGraphData: undefined,
  selectedGraphType: undefined,
  selectedGraphConfig: undefined,
};

export default Graph;
