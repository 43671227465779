export const listGraphsSites = /* GraphQL */ `
  query ListGraphsSites(
    $path: ID
    $filter: ModelGraphsSiteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGraphsSites(
      path: $path
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        path
        private
        Graphs {
          items {
            id
            data
            config
            label
            type
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getGraphsSite = /* GraphQL */ `
  query GetGraphsSite($path: ID!) {
    getGraphsSite(path: $path) {
      path
      private
      Graphs {
        items {
          id
          data
          config
          label
          type
          createdAt
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
