import { useDispatch, useSelector } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { resetNotificationToast } from '../../../store/slices';

function NotificationToast() {
  const dispatch = useDispatch();
  const { header, body, icon, show } = useSelector(
    ({ notificationToast }) => notificationToast
  );
  const handleClick = () => dispatch(resetNotificationToast());
  return (
    show && (
      <div className="position-fixed bottom-0 end-0 p-3">
        <Toast>
          <ToastHeader icon={icon} toggle={handleClick}>
            {header}
          </ToastHeader>
          <ToastBody>{body}</ToastBody>
        </Toast>
      </div>
    )
  );
}

export default NotificationToast;
