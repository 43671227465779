import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryInput: '',
};

const catalogCardsDataSlice = createSlice({
  name: 'catalogCardsData',
  initialState,
  reducers: {
    setQueryInput(state, { payload }) {
      state.queryInput = payload;
    },
  },
});

export const { setQueryInput } = catalogCardsDataSlice.actions;
export default catalogCardsDataSlice.reducer;
