import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Collapse,
  Navbar as NavbarComponent,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import enLang from '../../assets/i18n/en.json';
import './style.scss';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => setIsOpen(!isOpen);
  const handleClick = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NavbarComponent expand="md" color="primary" dark>
      <NavbarBrand to="/" tag={Link}>
        <img
          alt="logo"
          src="/logo192.png"
          style={{
            height: 40,
            width: 40,
          }}
        />
        {enLang.navbar.title}
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse isOpen={isOpen} navbar>
        <Button
          color="danger"
          className="ms-md-auto logout-button"
          type="button"
          onClick={handleClick}
        >
          {enLang.navbar.logout_button}
        </Button>
      </Collapse>
    </NavbarComponent>
  );
}

export default Navbar;
