import { useDispatch, useSelector } from 'react-redux';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { setPath } from '../../../store';
import enLang from '../../../assets/i18n/en.json';

function UrlInput() {
  const dispatch = useDispatch();
  const { graphsSitePath } = useParams();
  const urlInput = useSelector(({ graphsSite }) => graphsSite.path);
  const handleChange = ({ target }) => dispatch(setPath(target.value));
  return (
    <FormGroup>
      <Label htmlFor="desired-url">{enLang.editor.url_input.label}</Label>
      <InputGroup>
        <InputGroupText id="prefix-url">
          {document.location.origin}/client/
        </InputGroupText>
        <Input
          id="desired-url"
          aria-describedby="prefix-url"
          onChange={handleChange}
          value={urlInput}
          disabled={!!graphsSitePath}
          required
        />
      </InputGroup>
    </FormGroup>
  );
}

export default UrlInput;
