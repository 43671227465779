import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { graphsSelector } from '../../../store';
import HomeIcon from '../../../assets/images/HomeIcon.svg';

function Navbar() {
  const { graphsSitePath, graphId } = useParams();
  const graphsSiteData = useSelector(graphsSelector);

  return (
    <div className="btn-group" role="group" aria-label="Graph links">
      <Link className="btn btn-secondary" key={graphsSitePath} to="../">
        <img src={HomeIcon} alt="Inicio" />
      </Link>
      {graphsSiteData
        ? graphsSiteData.map((graph) => {
            if (graphId !== graph.id) {
              return (
                <Link
                  className="btn btn-secondary"
                  key={graph.id}
                  to={`../${graph.id}`}
                >
                  {graph.label}
                </Link>
              );
            }
            return null;
          })
        : null}
    </div>
  );
}

export default Navbar;
