import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VideoBackground } from '../../custom-components';
import { groupSelector } from '../../store';
import video from '../../assets/video/video-background.mp4';
import LoginForm from './login-form';
import './styles.scss';

const redirections = {
  analyst: '/analyst',
  client: '/client',
};

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const group = useSelector(groupSelector);
  let from;
  if (group in redirections) {
    from = redirections[group];
  }
  if (location.state?.from?.pathname.startsWith?.(from)) {
    from = location.state?.from?.pathname;
  }
  useEffect(() => {
    if (isLoggedIn) {
      navigate(from, { replace: true });
    }
  }, [isLoggedIn, navigate, from]);
  return <VideoBackground src={video} component={<LoginForm />} />;
}

export default Login;
