import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showModal: false,
  selectedGraphID: '',
};

const modalSlice = createSlice({
  name: 'deleteGraphModal',
  initialState,
  reducers: {
    toggleDeleteGraphModal(state) {
      state.showModal = !state.showModal;
    },
    setSelectedGraphID(state, { payload }) {
      state.selectedGraphID = payload;
    },
  },
});

export const { toggleDeleteGraphModal, setSelectedGraphID } =
  modalSlice.actions;
export default modalSlice.reducer;
