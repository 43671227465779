import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGraphsSite,
  setSelectedGraph,
  useGetGraphsSiteQuery,
  selectedGraphDataSelector,
  selectedGraphTypeSelector,
  selectedGraphConfigSelector,
} from '../../../store';
import Navbar from './navbar';
import { Graph } from '../../../custom-components';

function GraphPage() {
  const { graphsSitePath, graphId } = useParams();

  const { data, isLoading } = useGetGraphsSiteQuery(
    graphsSitePath ?? skipToken
  );

  const dispatch = useDispatch();
  if (data) {
    dispatch(setGraphsSite(data));
  }
  dispatch(setSelectedGraph(graphId));

  const seletedGraphData = useSelector(selectedGraphDataSelector);
  const selectedGraphType = useSelector(selectedGraphTypeSelector);
  const selectedGraphConfig = useSelector(selectedGraphConfigSelector);

  if (isLoading) {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 pt-3">
          <Navbar />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Graph
            rawSeletedGraphData={seletedGraphData}
            selectedGraphType={selectedGraphType}
            selectedGraphConfig={selectedGraphConfig}
          />
        </div>
      </div>
    </div>
  );
}

export default GraphPage;
