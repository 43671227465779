import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Form, Input } from 'reactstrap';
import { loginSubmit } from '../../store';
import enLang from '../../assets/i18n/en.json';
import ConfirmPasswordFormModal from './confirm-password-form-modal';

function LoginForm() {
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const user = data.get('user');
    const password = data.get('password');
    dispatch(loginSubmit({ user, password }));
  };

  return (
    <div className="container h-100 position-relative video-background-overlay__over d-flex align-items-center justify-content-center">
      <Card className="login-container">
        <CardBody>
          <CardTitle tag="h1" className="h2 text-center">
            <img src="/logo192.png" alt="Sociogramas logo" />
            {enLang.login.form.title}
          </CardTitle>
          <Form className="d-grid gap-3" onSubmit={handleSubmit}>
            <Input
              type="text"
              id="user"
              name="user"
              aria-describedby="user"
              placeholder={enLang.login.form.user_input}
              required
            />
            <Input
              type="password"
              id="password"
              name="password"
              aria-describedby="password"
              placeholder={enLang.login.form.password_input}
              required
            />
            <Button type="submit" color="primary" size="lg" block>
              {enLang.login.form.login_button}
            </Button>
          </Form>
        </CardBody>
      </Card>
      <ConfirmPasswordFormModal />
    </div>
  );
}

export default LoginForm;
