import { skipToken } from '@reduxjs/toolkit/query';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetGraphsSiteQuery } from '../../store';
import LandingPage from './landing-page';
import GraphPage from './graph-page';
import './styles.scss';

function Client() {
  const { graphsSitePath } = useParams();
  useGetGraphsSiteQuery(graphsSitePath ?? skipToken);
  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path="/:graphId" element={<GraphPage />} />
    </Routes>
  );
}

export default Client;
