import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import EditorComponent from './editor-component';
import {
  resetGraphsSite,
  usePublishGraphsSiteMutation,
  useUpdateGraphsSiteMutation,
  setSelectedGraphID,
  toggleDeleteGraphModal,
  removeGraph,
  selectedGraphSelector,
} from '../../../store';

function Editor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedGraph = useSelector(selectedGraphSelector);
  const [publishGraphsSite, { isSuccess: isSuccessPublishGraph }] =
    usePublishGraphsSiteMutation();

  const [updateGraphsSite, { isSuccess: isSuccessUpdateGraph }] =
    useUpdateGraphsSiteMutation();
  const publishGraphSiteSubmit = (e) => {
    e.preventDefault();
    publishGraphsSite();
  };
  const updateGraphsSiteSubmit = (e) => {
    e.preventDefault();
    updateGraphsSite();
  };

  const deleteSubmit = () => {
    if (!selectedGraph.createdAt) {
      dispatch(removeGraph());
      return;
    }
    dispatch(setSelectedGraphID(selectedGraph.id));
    dispatch(toggleDeleteGraphModal());
  };

  const deleteNoCreatedGraphSubmit = () => {
    dispatch(removeGraph());
  };

  useEffect(
    () => () => {
      dispatch(resetGraphsSite());
    },
    []
  );

  useEffect(() => {
    if (isSuccessPublishGraph) {
      navigate('/analyst/catalog');
    }
  }, [isSuccessPublishGraph]);

  useEffect(() => {
    if (isSuccessUpdateGraph) {
      navigate('/analyst/catalog');
    }
  }, [isSuccessUpdateGraph]);
  return (
    <Routes>
      <Route
        index
        element={
          <EditorComponent
            mutationSubmit={publishGraphSiteSubmit}
            deleteSubmit={deleteNoCreatedGraphSubmit}
          />
        }
      />
      <Route
        path=":graphsSitePath"
        element={
          <EditorComponent
            mutationSubmit={updateGraphsSiteSubmit}
            deleteSubmit={deleteSubmit}
          />
        }
      />
    </Routes>
  );
}

export default Editor;
