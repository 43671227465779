import { useDispatch, useSelector } from 'react-redux';
import {
  setGraphs,
  setSelectedGraph,
  setSelectedGraphID,
  toggleDeleteGraphModal,
  toggleGraphModal,
  useLazyGetGraphQuery,
  useListGraphsSitesQuery,
} from '../../../store';
import GraphsSite from './graphs-site';

function CatalogCards() {
  const queryInput = useSelector(
    (state) => state.catalogCardsDataSlice.queryInput
  );
  const [getGraph] = useLazyGetGraphQuery();
  const { isSuccess, data } = useListGraphsSitesQuery(queryInput);

  const dispatch = useDispatch();

  const showGraphModal = async (event) => {
    const { graphId } = event.currentTarget.dataset;
    const { data: graphData } = await getGraph(graphId);
    dispatch(setSelectedGraph(graphId));
    dispatch(setGraphs([graphData]));
    dispatch(toggleGraphModal());
  };

  const showDeleteGraphModal = (event) => {
    const { graphId } = event.currentTarget.dataset;
    dispatch(setSelectedGraphID(graphId));
    dispatch(toggleDeleteGraphModal());
  };

  if (!isSuccess || !Array.isArray(data)) {
    return null;
  }

  return (
    <>
      {data.map(({ path, Graphs }) => (
        <GraphsSite
          showDeleteGraphModal={showDeleteGraphModal}
          showGraphModal={showGraphModal}
          key={path}
          siteName={path}
          clientGraphs={Graphs.items.map(({ label, id, type }) => ({
            label,
            id,
            type,
          }))}
        />
      ))}
    </>
  );
}

export default CatalogCards;
