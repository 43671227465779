import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem, Button, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { ReactComponent as PieChartImage } from '../../../assets/images/pie-chart.svg';
import { ReactComponent as TrashImage } from '../../../assets/images/trash.svg';
import { ReactComponent as LinkImage } from '../../../assets/images/link.svg';
import {
  setGraphsToDelete,
  setselectedGraphsSitePath,
  toggleDeleteGraphsSiteModal,
} from '../../../store';

function GraphActionButtons({ id, showDeleteGraphModal, showGraphModal }) {
  return (
    <div className="float-end">
      <Button
        onClick={showGraphModal}
        data-graph-id={id}
        size="sm"
        className="graph-action-btn me-2"
      >
        <PieChartImage />
      </Button>
      <Button
        onClick={showDeleteGraphModal}
        data-graph-id={id}
        size="sm"
        className="graph-action-btn"
      >
        <TrashImage />
      </Button>
    </div>
  );
}

function GraphsSite({
  siteName,
  clientGraphs,
  showDeleteGraphModal,
  showGraphModal,
}) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setselectedGraphsSitePath(siteName));
    dispatch(setGraphsToDelete(clientGraphs));
    dispatch(toggleDeleteGraphsSiteModal());
  };
  const graphslist = clientGraphs.map(({ label, id, type, path }) => (
    <ListGroupItem key={id}>
      {label}
      <GraphActionButtons
        id={id}
        path={path}
        showDeleteGraphModal={showDeleteGraphModal}
        showGraphModal={showGraphModal}
        clientGraphs={{ label, type }}
      />
    </ListGroupItem>
  ));
  return (
    <Col>
      <Card>
        <div className="card-header">
          <Link to={`../editor/${siteName}`}>{siteName}</Link>
          <div className="button-group">
            <a
              target="_blank"
              rel="noreferrer"
              className="btn btn-link"
              size="sm"
              href={`/client/${siteName}`}
            >
              <LinkImage />
            </a>
            <Button onClick={handleClick} size="sm" close />
          </div>
        </div>
        <ListGroup flush>{graphslist}</ListGroup>
      </Card>
    </Col>
  );
}

GraphsSite.propTypes = {
  siteName: PropTypes.string.isRequired,
  clientGraphs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  showDeleteGraphModal: PropTypes.func.isRequired,
  showGraphModal: PropTypes.func.isRequired,
};

GraphActionButtons.propTypes = {
  id: PropTypes.string.isRequired,
  showDeleteGraphModal: PropTypes.func.isRequired,
  showGraphModal: PropTypes.func.isRequired,
  clientGraphs: PropTypes.shape({
    label: PropTypes.string,
    graphData: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default GraphsSite;
