import { createSelector } from '@reduxjs/toolkit';

const authState = (state) => state.auth;

const groupSelector = createSelector([authState], (auth) => {
  const groups =
    auth.user?.signInUserSession?.accessToken.payload['cognito:groups'];
  return Array.isArray(groups) ? groups?.[0] : undefined;
});

export default groupSelector;
