import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, confirmNewPassword } from '../../store';
import enLang from '../../assets/i18n/en.json';

function ConfirmPasswordFormModal() {
  const dispatch = useDispatch();
  const { newPasswordModal } = useSelector(({ auth }) => auth);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const newPassword = data.get('newPassword');
    const confirmNewPasswordInput = data.get('confirmNewPassword');
    if (newPassword === confirmNewPasswordInput) {
      dispatch(confirmNewPassword(newPassword));
    }
  };
  return (
    <Modal isOpen={newPasswordModal} size="sm" backdrop="static" centered>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>{enLang.login.modal.title}</ModalHeader>
        <ModalBody className="d-grid gap-3">
          <Input
            type="password"
            name="newPassword"
            placeholder={enLang.login.modal.new_password_input}
            required
          />
          <Input
            type="password"
            name="confirmNewPassword"
            placeholder={enLang.login.modal.confirm_new_password_input}
            required
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            {enLang.login.modal.cancel_button}
          </Button>
          <Button type="submit" color="warning">
            {enLang.login.modal.change_it_button}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default ConfirmPasswordFormModal;
