import PropTypes from 'prop-types';
import './styles.scss';

function VideoBackground({ src, component }) {
  return (
    <main className="position-relative bg-white w-100 vh-100 overflow-hidden">
      <div className="video-background-overlay position-absolute w-100 h-100 top-0 start-0 opacity-50 bg-black" />
      <video
        muted
        playsInline
        autoPlay
        loop
        className="video-background position-absolute top-50 start-50"
      >
        <source src={src} type="video/mp4" />
      </video>
      {component}
    </main>
  );
}

VideoBackground.propTypes = {
  src: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default VideoBackground;
