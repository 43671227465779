import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetGraphsSiteQuery } from '../../../store';
import video from '../../../assets/video/video-background.mp4';
import './styles.css';

function LandingPage() {
  const { graphsSitePath } = useParams();
  const { isSuccess, data } = useGetGraphsSiteQuery(
    graphsSitePath ?? skipToken
  );
  return (
    <div className="video-container">
      <div className="overlay"> </div>
      <video playsInline autoPlay muted loop src={video} />
      <div className="container h-100">
        <div className="d-flex h-100 text-center align-items-center">
          <div className="w-100 text-white">
            <h1 className="display-3">Tribes Dashboard by Antropomedia</h1>
            <div className="d-flex flex-column flex-lg-row flex-wrap gap-3 justify-content-center">
              {isSuccess
                ? data.Graphs.items.map((graph) => (
                    <Link
                      className="btn btn-lg btn-light"
                      key={graph.id}
                      to={`./${graph.id}`}
                      role="button"
                    >
                      {graph.label}
                    </Link>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
