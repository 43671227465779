import { Link } from 'react-router-dom';
import enLang from '../../../assets/i18n/en.json';

function AddNewGraphSite() {
  return (
    <Link
      className="btn btn-outline-success btn-lg flex-shrink-1 mx-3"
      to="../editor/"
    >
      {enLang.catalog.search_input.new_graph_site_link}
    </Link>
  );
}

export default AddNewGraphSite;
