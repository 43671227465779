import { Navigate, Route, Routes } from 'react-router-dom';

import { Navbar } from '../../custom-components';
import Editor from './editor';
import Catalog from './catalog';
import './styles.scss';

function Analyst() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="catalog" element={<Catalog />} />
        <Route path="editor/*" element={<Editor />} />
        <Route index element={<Navigate to="catalog" />} />
      </Routes>
    </>
  );
}

export default Analyst;
