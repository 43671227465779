import { createSelector } from '@reduxjs/toolkit';

const graphsSiteState = (state) => state.graphsSite;

export const optionsSelector = createSelector(graphsSiteState, (state) =>
  Object.values(state.graphs).map(({ id, label }) => ({ id, label }))
);

export const selectedGraphSelector = createSelector(graphsSiteState, (state) =>
  state.graphs.find(({ id }) => id === state.selectedGraph)
);

export const selectedGraphIdSelector = createSelector(
  graphsSiteState,
  (state) => state.selectedGraph
);

export const selectedGraphConfigSelector = createSelector(
  selectedGraphSelector,
  (selectedGraph) => selectedGraph?.config
);

export const selectedGraphDataSelector = createSelector(
  selectedGraphSelector,
  (selectedGraph) => selectedGraph?.data
);

export const selectedGraphTypeSelector = createSelector(
  selectedGraphSelector,
  (selectedGraph) => selectedGraph?.type
);

export const graphsSelector = createSelector(
  graphsSiteState,
  (state) => state.graphs
);
