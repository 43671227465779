import { Container, Row } from 'reactstrap';
import AddNewGraphSite from './add-new-graph-site';
import CatalogCards from './catalog-cards';
import SearchInput from './search-input';
import './styles.scss';
import DeleteGraphSiteModal from './delete-graph-site-modal';
import { DeleteModal } from '../../../custom-components';
import GraphModal from './graph-modal';

function Catalog() {
  return (
    <Container className="search-input">
      <Row>
        <SearchInput />
      </Row>
      <Row>
        <AddNewGraphSite />
      </Row>
      <Row xs={1} sm={2} md={3}>
        <CatalogCards />
        <DeleteGraphSiteModal />
        <GraphModal />
        <DeleteModal />
      </Row>
    </Container>
  );
}

export default Catalog;
