import { useRef } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setQueryInput } from '../../../store';
import { ReactComponent as Search } from '../../../assets/images/search.svg';
import enLang from '../../../assets/i18n/en.json';

function SearchInput() {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setQueryInput(inputRef.current.value));
  };

  return (
    <InputGroup size="lg">
      <Input
        placeholder={enLang.catalog.search_input.input_label}
        aria-label={enLang.catalog.search_input.input_label}
        innerRef={inputRef}
      />
      <Button className="search-input" onClick={handleButtonClick}>
        <Search />
      </Button>
    </InputGroup>
  );
}

export default SearchInput;
