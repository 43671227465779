import { Button } from 'reactstrap';
import enLang from '../../../assets/i18n/en.json';

function PostButton() {
  return (
    <Button
      color="antropomedia-red"
      size="lg"
      block
      className="position-sticky mt-2"
      type="submit"
    >
      {enLang.editor.graph_form.post_button}
    </Button>
  );
}

export default PostButton;
