import { useSelector, useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import {
  addGraph,
  optionsSelector,
  selectedGraphIdSelector,
  setSelectedGraph,
} from '../../../store';
import enLang from '../../../assets/i18n/en.json';

function Options() {
  const dispatch = useDispatch();
  const options = useSelector(optionsSelector);
  const selectedGraphId = useSelector(selectedGraphIdSelector);
  const handleClick = () => {
    const newGraph = { id: uuidv4(), label: 'Custom graph', type: '' };
    dispatch(addGraph(newGraph));
    dispatch(setSelectedGraph(newGraph.id));
  };
  const handleSelection = ({ target }) =>
    dispatch(setSelectedGraph(target.value));
  return (
    <>
      {options.map(({ label, id }) => (
        <FormGroup key={id} check>
          <Input
            name="options"
            id={id}
            value={id}
            type="radio"
            onChange={handleSelection}
            checked={selectedGraphId === id}
          />
          <Label htmlFor={id} check>
            {label}
          </Label>
        </FormGroup>
      ))}
      <Button color="primary" type="button" onClick={handleClick} block>
        {enLang.editor.options.add_graph_button}
      </Button>
    </>
  );
}

export default Options;
