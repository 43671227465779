/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGraphsSite = /* GraphQL */ `
  mutation CreateGraphsSite(
    $input: CreateGraphsSiteInput!
    $condition: ModelGraphsSiteConditionInput
  ) {
    createGraphsSite(input: $input, condition: $condition) {
      path
      private
      Graphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGraphsSite = /* GraphQL */ `
  mutation UpdateGraphsSite(
    $input: UpdateGraphsSiteInput!
    $condition: ModelGraphsSiteConditionInput
  ) {
    updateGraphsSite(input: $input, condition: $condition) {
      path
      private
      Graphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGraphsSite = /* GraphQL */ `
  mutation DeleteGraphsSite(
    $input: DeleteGraphsSiteInput!
    $condition: ModelGraphsSiteConditionInput
  ) {
    deleteGraphsSite(input: $input, condition: $condition) {
      path
      private
      Graphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGraph = /* GraphQL */ `
  mutation CreateGraph(
    $input: CreateGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    createGraph(input: $input, condition: $condition) {
      id
      data
      config
      label
      type
      createdAt
      updatedAt
      graphsSiteGraphsPath
      __typename
    }
  }
`;
export const updateGraph = /* GraphQL */ `
  mutation UpdateGraph(
    $input: UpdateGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    updateGraph(input: $input, condition: $condition) {
      id
      data
      config
      label
      type
      createdAt
      updatedAt
      graphsSiteGraphsPath
      __typename
    }
  }
`;
export const deleteGraph = /* GraphQL */ `
  mutation DeleteGraph(
    $input: DeleteGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    deleteGraph(input: $input, condition: $condition) {
      id
      data
      config
      label
      type
      createdAt
      updatedAt
      graphsSiteGraphsPath
      __typename
    }
  }
`;
