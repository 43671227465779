import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const transformFile = (file) => {
  const body = new FormData();
  body.append('file', file);
  return body;
};

export const generateGraphData = createAsyncThunk(
  'graphsSite/generateGraphData',
  async (tableFile, { getState }) => {
    const { graphsSite, auth } = getState();
    const selectedGraph = graphsSite.graphs.find(
      (graph) => graphsSite.selectedGraph === graph.id
    );
    if (!selectedGraph) {
      return undefined;
    }
    const { config, type } = selectedGraph;
    const body = transformFile(tableFile);
    Object.entries(config).forEach(([key, value]) => {
      if (key.includes('split')) {
        body.append(key, value.trim().split(/,\s*/g));
        return;
      }
      body.append(key, value.trim());
    });

    const { jwtToken } = auth.user.signInUserSession.idToken;
    const response = await API.post('tableToGraph', `/${type.toLowerCase()}`, {
      body,
      headers: { Authorization: `Bearer ${jwtToken}` },
    });

    return response;
  },
  {
    condition: (_, { getState }) => {
      const { graphs, selectedGraph } = getState().graphsSite;
      return graphs.some(({ id }) => id === selectedGraph);
    },
  }
);

const createGraphs = () => [
  {
    id: uuidv4(),
    label: 'Treemap',
    type: 'TREEMAP',
    data: undefined,
    config: {
      rootLabel: 'Benefits',
    },
  },
  {
    id: uuidv4(),
    label: 'Sunburst trademarks',
    type: 'SUNBURST',
    data: undefined,
    config: {
      splitHeaders: 'trademark, group, tribe',
    },
  },
  {
    id: uuidv4(),
    label: 'Sunburst social media',
    type: 'SUNBURST',
    data: undefined,
    config: {
      splitHeaders: 'media, group, tribe',
    },
  },
];

const initialState = {
  path: '',
  selectedGraph: '',
  graphs: createGraphs(),
  isTransformingData: false,
  submitData: {
    status: 'uninitialized',
    response: undefined,
    error: undefined,
  },
};

const selectedGraphIndexDraftSafeSelector = createDraftSafeSelector(
  (state) => state,
  ({ selectedGraph, graphs }) =>
    graphs.findIndex(({ id }) => id === selectedGraph)
);

const graphsSiteSlice = createSlice({
  name: 'graphsSite',
  initialState,
  reducers: {
    setPath: (state, { payload }) => {
      state.path = payload;
    },
    setSelectedGraph: (state, { payload }) => {
      state.selectedGraph = payload;
    },
    updateSelectedGraph: (state, { payload }) => {
      const graphIndex = selectedGraphIndexDraftSafeSelector(state);
      const { key, value } = payload;
      state.graphs[graphIndex] = { ...state.graphs[graphIndex], [key]: value };
    },
    updateSelectedGraphConfig: (state, { payload }) => {
      const graphIndex = selectedGraphIndexDraftSafeSelector(state);
      const { key, value } = payload;
      state.graphs[graphIndex].config = {
        ...state.graphs[graphIndex].config,
        [key]: value,
      };
    },
    addGraph: (state, { payload }) => {
      state.graphs.push(payload);
    },
    removeGraph: (state) => {
      state.graphs = state.graphs.filter(
        ({ id }) => id !== state.selectedGraph
      );
      state.selectedGraph = '';
    },
    setGraphs: (state, { payload }) => {
      state.graphs = payload;
    },
    resetGraphsSite: (state) => {
      state.path = '';
      state.graphs = createGraphs();
    },
    setGraphsSite: (state, { payload }) => {
      state.path = payload.path;
      state.graphs = payload.Graphs.items;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateGraphData.pending, (state) => {
      state.isTransformingData = true;
    });
    builder.addCase(generateGraphData.fulfilled, (state, { payload }) => {
      const graphIndex = selectedGraphIndexDraftSafeSelector(state);
      state.graphs[graphIndex].data = payload;
      state.isTransformingData = false;
    });
    builder.addCase(generateGraphData.rejected, (state) => {
      state.isTransformingData = false;
    });
  },
});

export const {
  setPath,
  setSelectedGraph,
  updateSelectedGraph,
  updateSelectedGraphConfig,
  addGraph,
  removeGraph,
  setGraphs,
  resetGraphsSite,
  setGraphsSite,
} = graphsSiteSlice.actions;

export default graphsSiteSlice.reducer;
