import { createApi } from '@reduxjs/toolkit/query/react';
import { API } from 'aws-amplify';
import { getGraph, listGraphs } from '../../graphql/queries';
import { getGraphsSite, listGraphsSites } from '../custom-queries';
import {
  createGraphsSite,
  createGraph,
  deleteGraph,
  deleteGraphsSite,
  updateGraph,
  updateGraphsSite,
} from '../../graphql/mutations';

const graphqlBaseQuery = async (graphqlParams) => {
  try {
    const result = await API.graphql(graphqlParams);
    return result;
  } catch (error) {
    return { error: { status: error.response?.status, data: error } };
  }
};

const sociogramasApi = createApi({
  reducerPath: 'sociogramasApi',
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    listGraphsSites: builder.query({
      query: (filter) => ({
        query: listGraphsSites,
        variables: {
          filter: {
            path: {
              contains: filter,
            },
          },
        },
      }),
      transformResponse: (response) =>
        response.listGraphsSites.items.map((graphsSite) => ({
          ...graphsSite,
          Graphs: {
            items: graphsSite.Graphs.items.map((graph) => ({
              ...graph,
              data: JSON.parse(graph.data),
              config: JSON.parse(graph.config),
            })),
          },
        })),
      providesTags: ['GraphsSite'],
    }),
    getGraphsSite: builder.query({
      query: (path) => ({
        query: getGraphsSite,
        variables: {
          path,
        },
      }),
      transformResponse: (response) => ({
        ...response.getGraphsSite,
        Graphs: {
          items: response.getGraphsSite.Graphs.items.map((graph) => ({
            ...graph,
            data: JSON.parse(graph.data),
            config: JSON.parse(graph.config),
          })),
        },
      }),
      providesTags: ['GraphsSite'],
    }),
    getGraph: builder.query({
      query: (id) => ({ query: getGraph, variables: { id } }),
      transformResponse: (response) => ({
        ...response.getGraph,
        data: JSON.parse(response.getGraph.data),
        config: JSON.parse(response.getGraph.config),
      }),
    }),
    listGraphs: builder.query({
      query: () => ({
        query: listGraphs,
      }),
    }),
    publishGraphsSite: builder.mutation({
      queryFn: async (_, { getState }, __, baseQuery) => {
        try {
          const { graphsSite } = getState();
          const {
            data: { createGraphsSite: graphsSiteResponse },
          } = await baseQuery({
            query: createGraphsSite,
            variables: {
              input: {
                path: graphsSite.path,
                private: false,
              },
            },
          });
          const graphsPublishRequest = graphsSite.graphs.map(
            ({ config, data, ...graph }) =>
              baseQuery({
                query: createGraph,
                variables: {
                  input: {
                    data: JSON.stringify(data),
                    config: JSON.stringify(config),
                    graphsSiteGraphsPath: graphsSiteResponse.path,
                    ...graph,
                  },
                },
              })
          );
          await Promise.all(graphsPublishRequest);
          return { data: graphsSiteResponse };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['GraphsSite'],
    }),
    deleteGraphsSite: builder.mutation({
      queryFn: async (_, { getState }, __, baseQuery) => {
        try {
          const { graphsSiteModalSlice } = getState();
          const {
            data: { deleteGraphsSite: graphsSiteResponse },
          } = await baseQuery({
            query: deleteGraphsSite,
            variables: {
              input: {
                path: graphsSiteModalSlice.selectedGraphsSitePath,
              },
            },
          });
          const graphsDeleteRequest = graphsSiteModalSlice.graphsToDelete.map(
            (id) =>
              baseQuery({
                query: deleteGraph,
                variables: {
                  input: {
                    id,
                  },
                },
              })
          );
          await Promise.all(graphsDeleteRequest);
          return { graphsSiteResponse };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['GraphsSite'],
    }),
    deleteGraph: builder.mutation({
      query: (id) => ({
        query: deleteGraph,
        variables: {
          input: {
            id,
          },
        },
      }),
      invalidatesTags: ['GraphsSite'],
    }),
    updateGraphsSite: builder.mutation({
      queryFn: async (_, { getState }, __, baseQuery) => {
        try {
          const { graphsSite } = getState();
          const {
            data: { updateGraphsSite: graphsSiteResponse },
          } = await baseQuery({
            query: updateGraphsSite,
            variables: {
              input: {
                path: graphsSite.path,
              },
            },
          });
          const graphsUpdateRequest = graphsSite.graphs.map(
            ({ config, data, createdAt, id, type, label, ...graph }) => {
              if (createdAt) {
                return baseQuery({
                  query: updateGraph,
                  variables: {
                    input: {
                      data: JSON.stringify(data),
                      graphsSiteGraphsPath: graphsSiteResponse.path,
                      id,
                      type,
                      label,
                      config: JSON.stringify(config),
                    },
                  },
                });
              }
              return baseQuery({
                query: createGraph,
                variables: {
                  input: {
                    data: JSON.stringify(data),
                    graphsSiteGraphsPath: graphsSiteResponse.path,
                    id,
                    type,
                    label,
                    config: JSON.stringify(config),
                    ...graph,
                  },
                },
              });
            }
          );
          await Promise.all(graphsUpdateRequest);
          return { graphsSiteResponse };
        } catch (error) {
          return { error };
        }
      },
    }),
    invalidatesTags: ['GraphsSite'],
  }),
});

export const {
  useListAllGraphsSitesQuery,
  useListGraphsSitesQuery,
  useListGraphsQuery,
  useGetGraphQuery,
  useLazyGetGraphQuery,
  useGetGraphsSiteQuery,
  usePublishGraphsSiteMutation,
  useDeleteGraphMutation,
  useDeleteGraphsSiteMutation,
  useUpdateGraphsSiteMutation,
} = sociogramasApi;

export default sociogramasApi;
