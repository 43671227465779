import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showModal: false,
  selectedGraphsSitePath: '',
  graphsToDelete: [],
};

const graphsSiteModalSlice = createSlice({
  name: 'deleteGraphsSiteModal',
  initialState,
  reducers: {
    toggleDeleteGraphsSiteModal(state) {
      state.showModal = !state.showModal;
    },
    setselectedGraphsSitePath(state, { payload }) {
      state.selectedGraphsSitePath = payload;
    },
    setGraphsToDelete(state, { payload }) {
      state.graphsToDelete = payload.map((graph) => graph.id);
    },
  },
});

export const {
  toggleDeleteGraphsSiteModal,
  setselectedGraphsSitePath,
  setGraphsToDelete,
} = graphsSiteModalSlice.actions;
export default graphsSiteModalSlice.reducer;
