import PropTypes from 'prop-types';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import {
  selectedGraphDataSelector,
  selectedGraphTypeSelector,
  selectedGraphConfigSelector,
  setGraphsSite,
  useGetGraphsSiteQuery,
} from '../../../store';
import GraphForm from './graph-form';
import Options from './options';
import UrlInput from './url-input';
import PostButton from './post-button';
import NotificationToast from './notification-toast';
import { DeleteModal, Graph } from '../../../custom-components';

function EditorComponent({ mutationSubmit, deleteSubmit }) {
  const dispatch = useDispatch();
  const { graphsSitePath } = useParams();

  const { data, isSuccess: isSuccessGetGraphsSite } = useGetGraphsSiteQuery(
    graphsSitePath ?? skipToken
  );
  useEffect(() => {
    if (isSuccessGetGraphsSite && graphsSitePath) {
      dispatch(setGraphsSite(data));
    }
  }, [data, graphsSitePath, isSuccessGetGraphsSite]);
  const seletedGraphData = useSelector(selectedGraphDataSelector);
  const selectedGraphType = useSelector(selectedGraphTypeSelector);
  const selectedGraphConfig = useSelector(selectedGraphConfigSelector);
  const isTransformingData = useSelector(
    ({ graphsSite }) => graphsSite.isTransformingData
  );
  return (
    <Container tag="form" onSubmit={mutationSubmit}>
      <Row>
        <Col>
          <UrlInput />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <Options />
        </Col>
        <Col xs="12" md="6">
          <GraphForm deleteSubmit={deleteSubmit} />
        </Col>
      </Row>
      <Row className={isTransformingData && 'justify-content-center'}>
        {isTransformingData && (
          <Col sm={1}>
            <Spinner />
          </Col>
        )}
        {seletedGraphData && (
          <Graph
            rawSeletedGraphData={seletedGraphData}
            selectedGraphType={selectedGraphType}
            selectedGraphConfig={selectedGraphConfig}
          />
        )}
        <PostButton />
      </Row>
      <NotificationToast />
      <DeleteModal />
    </Container>
  );
}

EditorComponent.propTypes = {
  mutationSubmit: PropTypes.func.isRequired,
  deleteSubmit: PropTypes.func.isRequired,
};

export default EditorComponent;
