import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  selectedGraphConfigSelector,
  selectedGraphDataSelector,
  selectedGraphTypeSelector,
  toggleGraphModal,
} from '../../../store';
import { Graph } from '../../../custom-components';

function GraphModal() {
  const dispatch = useDispatch();

  const showModal = useSelector(
    (store) => store.graphModalSlice.showGraphModal
  );

  const seletedGraphData = useSelector(selectedGraphDataSelector);
  const selectedGraphType = useSelector(selectedGraphTypeSelector);
  const selectedGraphConfig = useSelector(selectedGraphConfigSelector);

  const toggle = () => {
    dispatch(toggleGraphModal());
  };
  return (
    <Modal isOpen={showModal} size="xl">
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <Graph
          rawSeletedGraphData={seletedGraphData}
          selectedGraphType={selectedGraphType}
          selectedGraphConfig={selectedGraphConfig}
        />
      </ModalBody>
    </Modal>
  );
}

export default GraphModal;
