import { Navigate, Route, Routes } from 'react-router-dom';
import { Analyst, Login, Client } from './pages';
import { ProtectedRoute } from './custom-components';
import { useAuthListener } from './utils';

function App() {
  useAuthListener();

  return (
    <Routes>
      <Route
        path="analyst/*"
        element={
          <ProtectedRoute>
            <Analyst />
          </ProtectedRoute>
        }
      />
      <Route path="client/:graphsSitePath/*" element={<Client />} />
      <Route path="login" element={<Login />} />
      <Route index element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
