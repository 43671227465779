import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

const initialState = {
  user: undefined,
  isLoggedIn: false,
  newPasswordModal: false,
};

export const loginSubmit = createAsyncThunk(
  'auth/loginSubmit',
  ({ user, password }) => Auth.signIn(user, password)
);
export const confirmNewPassword = createAsyncThunk(
  'auth/confirmNewPassword',
  async (newPassword, { getState }) => {
    const { user } = getState().auth;
    return Auth.completeNewPassword(user, newPassword);
  }
);
export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async () => Auth.currentAuthenticatedUser()
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.newPasswordModal = false;
    },
    updateIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
      if (payload === false) {
        state.user = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginSubmit.fulfilled, (state, { payload }) => {
      state.user = payload;
      if (payload?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        state.newPasswordModal = true;
        return;
      }

      state.isLoggedIn = true;
    });
    builder.addCase(confirmNewPassword.fulfilled, (state, { payload }) => {
      state.newPasswordModal = false;
      state.user = payload;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = payload;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoggedIn = false;
      state.user = undefined;
    });
  },
});

export const { closeModal, updateIsLoggedIn } = authSlice.actions;

export default authSlice.reducer;
