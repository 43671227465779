import { configureStore } from '@reduxjs/toolkit';
import sociogramasApi from './slices/sociogramas-api';
import graphsSiteReducer from './slices/graphs-site';
import notificationToastReducer from './slices/notification-toast';
import authReducer from './slices/auth';
import deleteGraphModalReducer from './slices/delete-graph';
import graphsSiteModalSliceReducer from './slices/delete-graphs-site';
import graphModalSliceReducer from './slices/graph-modal';
import catalogCardsDataSliceReducer from './slices/catalog-cards-data';

const store = configureStore({
  reducer: {
    graphsSite: graphsSiteReducer,
    notificationToast: notificationToastReducer,
    auth: authReducer,
    deleteGraphModal: deleteGraphModalReducer,
    graphsSiteModalSlice: graphsSiteModalSliceReducer,
    graphModalSlice: graphModalSliceReducer,
    catalogCardsDataSlice: catalogCardsDataSliceReducer,
    [sociogramasApi.reducerPath]: sociogramasApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['auth', 'graphs'],
        ignoredActionPaths: ['payload', 'meta.arg'],
        ignoredActions: ['auth/setAuth', 'graphs/get/fulfilled'],
      },
    }).concat(sociogramasApi.middleware),
});

export default store;
