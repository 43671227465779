import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import {
  toggleDeleteGraphsSiteModal,
  useDeleteGraphsSiteMutation,
} from '../../../store';
import enLang from '../../../assets/i18n/en.json';

function DeleteGraphSiteModal() {
  const dispatch = useDispatch();

  const showModal = useSelector(
    (store) => store.graphsSiteModalSlice.showModal
  );

  const selectedGraphsSitePath = useSelector(
    (store) => store.graphsSiteModalSlice.selectedGraphsSitePath
  );

  const toggle = () => {
    dispatch(toggleDeleteGraphsSiteModal());
  };

  const [deleteGraphsSite] = useDeleteGraphsSiteMutation();
  const handleSubmit = () => {
    deleteGraphsSite(selectedGraphsSitePath);
    toggle();
  };
  return (
    <Modal isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {enLang.catalog.modal_delete_graphs_site.header}
        {selectedGraphsSitePath}
      </ModalHeader>
      <ModalBody>{enLang.catalog.modal_delete_graphs_site.title}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleSubmit}>
          {enLang.catalog.modal_delete_graphs_site.delete_button}
          {selectedGraphsSitePath}
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          {enLang.catalog.modal_delete_graphs_site.cancel_button}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteGraphSiteModal;
