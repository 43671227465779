import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { selectedGraphSelector, updateSelectedGraph } from '../../../store';
import { GRAPH_TYPES } from '../../../utils';
import enLang from '../../../assets/i18n/en.json';
import complementaryInputs from './complementary-inputs';
import SendFileButton from './send-file-button';

function GraphForm({ deleteSubmit }) {
  let extraFields = null;
  const dispatch = useDispatch();
  const selectedGraph = useSelector(selectedGraphSelector);
  const handleChange = ({ target }) =>
    dispatch(updateSelectedGraph({ key: target.id, value: target.value }));

  if (selectedGraph === undefined) {
    return (
      <Alert color="warning">{enLang.editor.graph_form.select_a_graph}</Alert>
    );
  }
  if (selectedGraph.type in complementaryInputs) {
    const ExtraFieldsComponent = complementaryInputs[selectedGraph.type];
    extraFields = <ExtraFieldsComponent />;
  }
  return (
    <>
      <FormGroup>
        <Label for="label">{enLang.editor.graph_form.name}</Label>
        <Input
          id="label"
          placeholder={enLang.editor.graph_form.name_placeholder}
          value={selectedGraph.label}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">{enLang.editor.graph_form.type}</Label>
        <Input
          id="type"
          name="type"
          type="select"
          value={selectedGraph.type}
          onChange={handleChange}
        >
          <option value="" disabled hidden>
            {enLang.editor.graph_form.type_placeholder}
          </option>
          {GRAPH_TYPES.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </Input>
      </FormGroup>
      {extraFields}
      <Row>
        <Col>
          <Button
            type="button"
            color="danger"
            className="text-white"
            onClick={deleteSubmit}
            block
          >
            {enLang.editor.graph_form.delete_graph}
          </Button>
        </Col>
        <SendFileButton />
      </Row>
    </>
  );
}

GraphForm.propTypes = {
  deleteSubmit: PropTypes.func.isRequired,
};

export default GraphForm;
