import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showGraphModal: false,
  graphData: {},
};

const graphModalSlice = createSlice({
  name: 'graphModal',
  initialState,
  reducers: {
    toggleGraphModal(state) {
      state.showGraphModal = !state.showGraphModal;
    },
    setSelectedGraphData(state, { payload }) {
      state.graphData = payload;
    },
  },
});

export const { toggleGraphModal, setSelectedGraphData } =
  graphModalSlice.actions;
export default graphModalSlice.reducer;
