import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toggleDeleteGraphModal, useDeleteGraphMutation } from '../../store';
import enLang from '../../assets/i18n/en.json';

function DeleteModal() {
  const dispatch = useDispatch();

  const showModal = useSelector((store) => store.deleteGraphModal.showModal);

  const selectedGraphID = useSelector(
    (store) => store.deleteGraphModal.selectedGraphID
  );

  const toggle = () => {
    dispatch(toggleDeleteGraphModal());
  };

  const [deleteGraph] = useDeleteGraphMutation();
  const handleSubmit = () => {
    deleteGraph(selectedGraphID);
    toggle();
  };
  return (
    <Modal isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {enLang.catalog.modal_delete_graphs.header}
      </ModalHeader>
      <ModalBody>{enLang.catalog.modal_delete_graphs.title}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleSubmit}>
          {enLang.catalog.modal_delete_graphs.delete_button}
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          {enLang.catalog.modal_delete_graphs.cancel_button}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteModal;
